<template>

    <CEESAR-ControlBox  :label="label"
                        :required="required">

        <div class="CEESAR-CheckBoxEntries">
            <div class="CEESAR-CheckBoxEntry" v-for="entry in entries" v-bind:key="entry.id">

                <div class="CEESAR-CheckBoxEntryLabel"
                    v-on:click="OnChanged(entry.id, !entry.checked)">
                    {{entry.label}}
                </div>

                <input class="CEESAR-CheckBoxEntryField"
                    type="checkbox"
                    :checked="entry.checked == true"
                    v-on:change="OnChanged(entry.id, !entry.checked)">

            </div>
        </div>

    </CEESAR-ControlBox>
</template>

<script>
export default {
    name:"CEESAR-CheckBox",
    props:{
        label: "",
        entries: Array,
        required: false
    },
    methods:{
        OnChanged(pId, pChecked){
            this.$emit('changed', pId, pChecked);
        }
    }
}
</script>

<style lang="less">

</style>